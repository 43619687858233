
import { Options, Vue } from "vue-class-component";
import MandatoryFieldFlag from "@/components/Form/MandatoryFieldFlag.vue";
import TextInput from "@/components/Form/TextInput.vue";
import RadioButtonGroup from "@/components/Form/RadioButtonGroup.vue";
import Select from "@/components/Form/Select.vue";
import Accordion from "@/components/Accordion.vue";
import CourseInput from "@/components/CourseInput.vue";
import Modal from "@/components/Modal.vue";
import LuSpinner from "@/components/Lu/LuSpinner.vue";
import TermService from "@/services/term-service";
import NoteTakerService from "@/services/notetaker-service";
import {
  Course,
  ProposedCourse,
  SupportCourseOccurrence,
} from "@/interfaces/api";
import { PersonCourse } from "@/interfaces/PersonCourse";
import DateHelper from "@/helpers/date-helper";
import IdHelper from "@/helpers/id-helper";
import StringHelper from "@/helpers/string-helper";
import TermHelper from "@/helpers/term-helper";
import CourseService from "@/services/course-service";

@Options({
  components: {
    MandatoryFieldFlag,
    TextInput,
    RadioButtonGroup,
    Select,
    Accordion,
    CourseInput,
    Modal,
    LuSpinner,
  },
  data: () => ({
    hasBeenSent: false,
    /**
     * The note taker that is applying
     */
    noteTaker: null,
    /**
     * The current term
     */
    currentTerm: null,
    /**
     * The person´s courses
     */
    courses: null,
    /**
     * A json string representing the state of the note-taker on load
     * Used in order to enable the submit button
     */
    noterTakerOnload: null,
    /**
     * A json string representing the uids of the selected courses on load
     * Used in order to enable the submit button
     */
    selectedCourseUidsOnLoad: null,
    /**
     * Updated when courses are selected or deselected
     * Used in order to enable the submit button
     */
    selectedCourseUids: [],
    /**
     * Used to show or hide spinner
     */
    isLoading: false,
  }),
  methods: {
    /**
     * Fetches the note taker
     */
    getNoteTaker(): void {
      this.isLoading = true;
      const luEduPersonPrimaryId = IdHelper.getLuEduPersonPrimaryId(
        this.$route.query.lucatid
      );

      NoteTakerService.getApplicant(luEduPersonPrimaryId)
        .then((data) => {
          this.noteTaker = data;

          if (
            this.noteTaker.isAnActiveStudentInNeed ||
            this.personHasInterimPersonalId
          ) {
            this.isLoading = false;
            this.$refs["not-possible-modal"].open();
            return;
          }

          this.courses = this.noteTaker.courses.map((course: Course) => {
            return {
              ...course,
              checked: this.isProposedCourse(course.courseUid),
              disabled:
                this.isSupportCourse(course.courseUid) ||
                this.isCompletedCourse(course.courseUid) ||
                this.isDeletedByAdmin(course.courseUid),
            };
          });
          this.getItemsOnLoad();
          this.isLoading = false;
        })
        .catch((e: Error) => {
          console.error(e);
        });
    },
    /**
     * Gets called when the user clicks the submit button
     * Sends request information to the backend
     */
    sendApplication(): void {
      this.hasBeenSent = true;
      this.isLoading = true;
      if (
        this.noteTaker.id !== 0 ||
        this.applicationData.proposedCourses.length > 0
      ) {
        NoteTakerService.addOrUpdateNoteTaker(this.applicationData).then(
          (result: boolean) => {
            if (result) {
              this.$refs["success-modal"].open();
            } else {
              this.$refs["error-modal"].open();
            }
            this.isLoading = false;
            this.hasBeenSent = false;
            this.setNoteTakerAndSelectedCourseUidsOnLoad();
          }
        );
      } else {
        this.isLoading = false;
        this.hasBeenSent = false;
        this.$refs["alert-modal"].open();
      }
    },
    /**
     * Get courses within a term
     *
     * @param {string} term The term
     * @returns {PersonCourse[]} The courses within the term
     */
    getCoursesByTerm(term: string): PersonCourse[] {
      return TermHelper.getCoursesByTerm(this.courses, term);
    },
    getTermString(term: string): string {
      return TermHelper.getTermString(term, this.$t);
    },
    /**
     * Checks whether the course with the specified course Uid is one of the note-taker's current proposed courses
     * Used for setting the checked attribute on the course checkbox
     *
     * @param {string} courseUid The course Uid
     * @returns {boolean} whether the course is one of the note-taker's current proposed courses
     */
    isProposedCourse(courseUid: string): boolean {
      if (!this.noteTaker.proposedCourses) {
        return false;
      }

      const proposedCourse = this.noteTaker.proposedCourses.find(
        (course: ProposedCourse) =>
          course.courseUid.toLowerCase() === courseUid.toLowerCase()
      );

      return proposedCourse != null;
    },
    /**
     * Checks whether the course with the specified course Uid is one of the note-taker's current active support courses
     * Used for setting the disabled attribute on the course checkbox
     *
     * @param {string} courseUid The course Uid
     * @returns {boolean} whether the course is one of the note-taker's current active support courses
     */
    isSupportCourse(courseUid: string): boolean {
      if (!this.noteTaker.supportCourseOccurrences) {
        return false;
      }

      const supportCourse = this.noteTaker.supportCourseOccurrences.find(
        (course: SupportCourseOccurrence) =>
          course.courseUid.toLowerCase() === courseUid.toLowerCase() &&
          course.assignmentStart !== null
      );

      return supportCourse != null;
    },
    /**
     * Checks whether the course with the specified course Uid has an end date that has passed
     * Used for setting the disabled attribute on the course checkbox
     *
     * @param {string} courseUid The course Uid
     * @returns {boolean} whether the course has an end date that has passed
     */
    isCompletedCourse(courseUid: string): boolean {
      return CourseService.isCompletedCourse(courseUid, this.noteTaker.courses);
    },
    /**
     * Gets the course end date for the specified course
     *
     * @param {string} courseUid The course Uid
     * @returns {number} The course end date
     */
    getCourseEndDate(courseUid: string): string {
      if (!this.noteTaker.courses) {
        return "";
      }

      const completedCourse = this.noteTaker.courses.find(
        (course: Course) =>
          course.courseUid.toLowerCase() === courseUid.toLowerCase()
      );

      return completedCourse != null ? completedCourse.courseEndDate : "";
    },
    /**
     * Gets the course occurrence id for the specified course
     *
     * @param {string} courseUid The course Uid
     * @returns {number} The course occurrence id
     */
    getCourseOccurrenceId(courseUid: string): number {
      if (!this.noteTaker.supportCourseOccurrences) {
        return 0;
      }

      const supportCourse = this.noteTaker.supportCourseOccurrences.find(
        (course: SupportCourseOccurrence) =>
          course.courseUid.toLowerCase() === courseUid.toLowerCase()
      );

      return supportCourse ? supportCourse.courseOccurrenceId : 0;
    },
    /*
     * Navigates to the student home page
     */
    goToStudentHome() {
      this.$router.push({ name: "StudentHome" });
    },
    /*
     * Tool tip text for disabled course checkboxes
     */
    tooltipText(isDisabled: boolean, courseUid: string): string {
      const isCompletedCourse = this.isCompletedCourse(courseUid);
      const courseEndDate = this.getCourseEndDate(courseUid);
      const isDeletedByAdmin = this.isDeletedByAdmin(courseUid);

      return isDisabled
        ? isCompletedCourse
          ? this.$t("note-taking-request.completed-course", {
              date: DateHelper.formatDate(courseEndDate),
            })
          : isDeletedByAdmin
          ? this.$t("note-taking-request.cannot-reregister")
          : this.$t("note-taking-request.cannot-deregister")
        : null;
    },
    /*
     * Gets the state of the note-taker and proposed courses
     * Called when page loads and is used to check if data has been updated
     */
    getItemsOnLoad() {
      this.selectedCourseUids = this.noteTaker.proposedCourses
        ? this.noteTaker.proposedCourses
            .map((course: ProposedCourse) => course.courseUid.toLowerCase())
            .sort()
        : [];
      this.setNoteTakerAndSelectedCourseUidsOnLoad();
    },
    /*
     * Emitted from the CourseInput component when the course checkbox state is changed
     */
    onCourseCheckedChanged(course: PersonCourse): void {
      const courseUid = course.courseUid.toLowerCase();

      const isCurrentlySelected = this.selectedCourseUids.find(
        (x: string) => x.toLowerCase() === courseUid
      );

      if (course.checked === true) {
        if (!isCurrentlySelected) {
          this.selectedCourseUids.push(courseUid);
        }
      } else {
        if (isCurrentlySelected) {
          this.selectedCourseUids = this.selectedCourseUids.filter(
            (x: string) => x.toLowerCase() !== courseUid
          );
        }
      }

      this.selectedCourseUids = this.selectedCourseUids.sort();
    },
    /*
     * Gets the state of the note-taker and selected courses
     * Called when page loads or is updated and saved and is used to check if data has been updated
     */
    setNoteTakerAndSelectedCourseUidsOnLoad(): void {
      this.noterTakerOnload = StringHelper.stringifyToLowerCase(this.noteTaker);
      this.selectedCourseUidsOnLoad = StringHelper.stringifyToLowerCase(
        this.selectedCourseUids
      );
    },
    /**
     * Reloads the view
     */
    reloadView(): void {
      if (this.noteTaker.id === 0) {
        window.location.reload();
      }
    },
    /**
     * Checks whether the course with the specified course Uid has been ended by admin
     * Used for setting the disabled attribute on the course checkbox
     *
     * @param {string} courseUid The course Uid
     *
     * @returns {boolean} whether the course has been ended by admin
     *
     */ isDeletedByAdmin(courseUid: string): boolean {
      if (!this.noteTaker.supportCourseOccurrences) {
        return false;
      }

      const supportCourse = this.noteTaker.supportCourseOccurrences.find(
        (course: SupportCourseOccurrence) =>
          course.courseUid.toLowerCase() === courseUid.toLowerCase() &&
          course.deletedByAdmin === true
      );

      return supportCourse != null;
    },
  },
  computed: {
    /**
     * Gets the note-taker, with filled information and proposed courses
     */
    applicationData() {
      const courses: PersonCourse[] = this.courses.filter(
        (course: PersonCourse) => course.checked
      );
      // Add proposedCourses to note taker
      let data = {
        ...this.noteTaker,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        proposedCourses: courses.map(({ checked, ...course }) => course),
      };

      return data;
    },
    /**
     * Gets the terms
     * Eg: ['VT22', 'HT22']
     */
    terms(): string[] {
      return TermHelper.getTerms(this.courses);
    },
    /**
     * Sets the submit button text depending on if it is the first time the data is being saved
     */
    submitButtonText(): string {
      return this.noteTaker.id === 0
        ? this.$t("shared.save")
        : this.$t("shared.update");
    },
    /**
     * Sets the popup success message depending on if it is the first time the data is being saved
     */
    popupSuccessMessage(): string {
      return this.noteTaker.id === 0
        ? this.$t("note-taking-request.popup-success.message", {
            email: this.noteTaker.email,
          })
        : this.$t("note-taking-request.popup-success.updateMessage");
    },
    /*
     * Checks if data has been updated in order to enable the submit button
     */
    dataIsUpdated(): boolean {
      return (
        StringHelper.stringifyToLowerCase(this.noteTaker) !==
          this.noterTakerOnload ||
        StringHelper.stringifyToLowerCase(this.selectedCourseUids) !==
          this.selectedCourseUidsOnLoad
      );
    },
    /**
     * Checks if the logged in user has an interim personal id
     * Used in order to prevent the user making an application
     */
    personHasInterimPersonalId(): boolean {
      const personalId = this.noteTaker.personalId;
      const regex = /^\d{12}$/;
      return !personalId.match(regex);
    },
  },
  async mounted() {
    this.currentTerm = await TermService.getCurrentTerm();
    this.getNoteTaker();
  },
})
export default class NoteTakingRequest extends Vue {}
