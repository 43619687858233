import { StudyTermInfo } from "@/interfaces/api";
import BaseService from "@/services/base-service";

class TermService extends BaseService {
  /**
   * Fetches StudyTermInfo for current and previous term
   *
   * @returns {Promise<Faculty>} A promise with the StudyTermInfo
   */
  getStudyTermInfo(): Promise<StudyTermInfo> {
    const endpointController = "Term";
    const endpointRoute = "studyTermInfo";
    const getStudyTermInfo = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}`;
    return this.fetch<StudyTermInfo>(getStudyTermInfo);
  }

  /**
   * Fetches the current term
   *
   * @returns {Promise<string>} A promise with the current term
   */
  getCurrentTerm(): Promise<string> {
    return this.getStudyTermInfo().then(
      (studyTermInfo) => studyTermInfo.thisTerm
    );
  }

  /**
   * Fetches the previous term
   *
   * @returns {Promise<string>} A promise with the previous term
   */
  getPreviousTerm(): Promise<string> {
    return this.getStudyTermInfo().then(
      (studyTermInfo) => studyTermInfo.previousTerm
    );
  }
}

export default new TermService();
